"use client";

/**
 * Third-party libraries.
 */
import { PropsWithChildren } from "react";

/**
 * Project components.
 */
import { DefaultProtectedPageLayout, DefaultProtectedPageLayoutHeader, DefaultProtectedPageLayoutSidebar } from "@/components/client/layout";
import { Auth0Permission } from "@/components/common/auth0/enumerations";

/**
 * Application's default protected layout properties.
 */
type ApplicationDefaultProtectedPageLayoutProps = PropsWithChildren<{
  /**
   * Permissions required to access the page.
   * - allow access to children when no permissions are required.
   */
  requiredPermissions?: Auth0Permission[];
}>;

/**
 * Application's default protected page layout.
 *
 * Wrap your page with this when you want the default project header, sidebar and authentication
 * for the page.
 */
export function ApplicationDefaultProtectedPageLayout({
  children,
  requiredPermissions: allowedPermissions = []
}: ApplicationDefaultProtectedPageLayoutProps) {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <DefaultProtectedPageLayout header={<DefaultProtectedPageLayoutHeader />} sidebar={<DefaultProtectedPageLayoutSidebar />} requiredPermissions={allowedPermissions} data-sentry-element="DefaultProtectedPageLayout" data-sentry-component="ApplicationDefaultProtectedPageLayout" data-sentry-source-file="application-default-protected-page-layout.tsx">
      {children}
    </DefaultProtectedPageLayout>;
}